.footer {
    height: 30px;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 5px;
    margin-bottom: 10px;
    max-width: 1728px;
    width: 100%;
    margin: 0px auto;
}

.footer .copy-right-txt {
    color: #000000;
    display: flex;
    font-size: 12px;
}

.footer .inhive-txt {
    color: #38bbeb;
    font-weight: bold;
    margin-left: 3px;
    margin-right: 3px;
}

.footer .text-align-right {
    text-align: right;
}

.footer .version-txt {
    color: #000000;
}

@media only screen and (max-width: 480px) {
    .footer .text-align-right {
        text-align: left;
        margin-bottom: 20px;
    }
}