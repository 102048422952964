.MuiAppBar-positionFixed {
    z-index: 1 !important;
}

.react-responsive-modal-modal {
    border-radius: 10px;
}

/* .MuiPaper-root form .MuiGrid-root .MuiBox-root .MuiFormControl-root .MuiInputBase-input {
    padding: 8px 14px;
}
.MuiPaper-root form .MuiGrid-root .MuiBox-root .MuiFormLabel-root {
    font-size: 11px !important;
}

.MuiPopover-root .MuiPaper-root .MuiList-root li.MuiButtonBase-root {
    font-size: 11px;
}

.MuiDrawer-modal .MuiBackdrop-root {
  background-color: inherit !important;
}

.makeStyles-crUserInfo-39 {
    padding-right: 0px;
}
.makeStyles-navItem-54 {
    padding-right: 5px;
} */

@media (min-width: 1280px) {
    .makeStyles-container-12 {
        /* width: 16.5rem !important; */
    }

    .makeStyles-appBar-94 {
        width: calc(100vw - 16.5rem) !important;
    }
}

@media (min-width: 0px) {
    .makeStyles-crUserInfo-39 {
        padding-right: 0;
    }
}