.header-shadow {
    box-shadow: 0 3px 5px -3px #000;
    padding: 10px 15px;
    background-color: #0C6AC9;
}

.header-shadow .chat-msg-label {
    /* color: #1B1D1F; */
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2px;
    margin-top: 2px;
}

.header-shadow .email-label {
    /* color: #858A8C; */
    color: #FFFFFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2px;
    margin-top: 2px;
}

.header-shadow .ta-end-ac-center {
    text-align: end;
    align-content: center;
}

.content-bg {
    background-color: #F7F7F7;
    overflow-x: hidden;
    overflow-y: auto;
}

.chat-msg-modal .wh-500 {
    width: 500px;
    height: 500px;
}

.chat-msg-modal .left-chat-bg {
    position: relative;
    background: #FFFFFF;
    padding: 8px 10px;
    padding-bottom: 5px;
    border-radius: 10px;
    margin-right: 10%;
    margin-top: 10px;
    margin-bottom: 10px;
    width: fit-content;
    justify-self: self-start;
    box-shadow: 2px 2px 10px #d2d2d2;
}

.chat-msg-modal .left-chat-bg:after {
    content: '';
    position: absolute;
    border: 10px solid transparent;
    border-top: 10px solid #FFFFFF;
    top: 0px;
    left: -10px;
}

.chat-msg-modal .left-chat-user {
    color: #111B21;
    font-size: 15px;
    font-weight: 800;
    line-height: normal;
}

.chat-msg-modal .left-chat-senderRole {
    color: #111B21;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
    margin-top: 3px;
}

.chat-msg-modal .left-chat-msg {
    color: #111B21;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-top: 2px;
}

.chat-msg-modal .left-chat-time {
    color: #667781;
    font-size: 9px;
    font-weight: 400;
    line-height: normal;
    text-align: end;
    margin-top: 2px;
}

.chat-msg-modal .right-chat-bg {
    position: relative;
    background: #cbe8f0;
    padding: 8px 10px;
    padding-bottom: 5px;
    border-radius: 10px;
    /* margin-left: 25%; */
    margin-right: 3%;
    margin-top: 10px;
    margin-bottom: 10px;
    width: fit-content;
    justify-self: end;
    box-shadow: 2px 2px 10px #d2d2d2;
}

.chat-msg-modal .right-chat-bg:after {
    content: '';
    position: absolute;
    border: 10px solid transparent;
    border-top: 10px solid #cbe8f0;
    top: 0px;
    right: -10px;
}

.chat-msg-modal .right-chat-user {
    color: #111B21;
    font-size: 15px;
    font-weight: 800;
    line-height: normal;
}

.chat-msg-modal .right-chat-senderRole {
    color: #111B21;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
    margin-top: 3px;
}

.chat-msg-modal .right-chat-msg {
    color: #111B21;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.chat-msg-modal .right-chat-time {
    color: #667781;
    font-size: 9px;
    font-weight: 400;
    line-height: normal;
    text-align: end;
    margin-top: 2px;
}

.chat-msg-modal .grid-container-col {
    display: grid;
    grid-template-columns: 10% 90%;
    width: 100%;
}

.chat-msg-modal .grid-col-item-left {
    justify-self: flex-start;
    padding-top: 12px;
}

.chat-msg-modal .user-profile-letter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: 2px solid #FFFFFF;
    text-transform: uppercase;
}

.chat-msg-modal .grid-col-item-right {
    justify-self: flex-end;
    padding-top: 12px;
}

.chat-msg-modal .user-profile-border {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    /* border: 1px solid #667781; */
}

.chat-msg-modal .user-profile-img {
    height: 40px;
    width: 40px;
    object-fit: contain;
}

.pl-10 {
    padding-left: 10x;
}

.msg-action {
    box-shadow: 0px 0px 3px #000;
    background-color: #F7F7F7;
    padding: 10px 20px;
}

.date-container {
    text-align: center;
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
}

.date-text {
    background-color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 10px;
    display: inline-block;
    border: 1px solid #f1f1f1;
}